<template>
    <nav-bar/>
    <div v-if="isLoading" class="">
        <loading/>
    </div>
    <div v-else-if="isError">
        <not-found/>
    </div>
    <div v-else>

        <div v-if="page.page">
            <div v-for="block in parentBlocks" :key="block.id">
                <component :is="block.block" :key="block.id" :data="block.data"></component>
            </div>

            <div v-if="parentSubs.length>0">

                <section class="page-navigation">

                    <div class="container">
                        <div class="header row">

                            <div class="col" v-for="(item, index) in parentSubs" :key="index">
                                <a class="button block" :href="`/${item.slug}`"
                                   :class="{'orange': item.slug !== slug, 'white': item.slug === slug}">
                                    {{item.title}}
                                </a>
                            </div>
                        </div>
                    </div>
                </section>
            </div>

        </div>

        <div v-if="blocks.length ===0 ">
            <page-header :data='{title: "Səhifə hazırlanma mərhələsindədir"}'></page-header>
        </div>
        <div v-for="block in blocks" :key="block.id">
            <component :is="block.block" :key="block.id" :data="block.data"></component>
        </div>

        <div v-if="subs.length>0">

            <section class="page-navigation">

                <div class="container">
                    <div class="header row">

                        <div class="col" v-for="(item, index) in subs" :key="index">
                            <a class="button block" :href="`/${item.slug}`"
                               :class="{'orange': index !== currentIndex, 'white': index === currentIndex}">
                                {{item.title}}
                            </a>
                        </div>
                    </div>
                </div>
            </section>
        </div>
    </div>

    <the-footer/>


    <div v-show="isLoading" class="">
<!--        <loading-fullscreen/>-->
    </div>

</template>

<script>
    import {getPage} from "../repositories/GeneralDataRepository";

    import PageHeader from "../components/sections/PageHeader";
    import NotFound from "./NotFound";
    import NavBar from "../components/NavBar";
    import TheFooter from "../components/TheFooter";
    import TextBlockBasic from "../components/sections/TextBlockBasic";
    import ContactPersons from "../components/sections/ContactPersons";
    import InfoBlocksFirst from "../components/sections/InfoBlocksFirst";
    import InfoBlocksSecond from "../components/sections/InfoBlocksSecond";
    import InfoBlocksThird from "../components/sections/InfoBlocksThird";
    import InfoBlocksFourth from "../components/sections/InfoBlocksFourth";
    import SponsorInfo from "../components/sections/SponsorInfo";
    import SponsorList from "../components/sections/SponsorList";
    import SponsorshipPackages from "../components/sections/SponsorshipPackages";
    import TabsView from "../components/sections/TabsView";
    import TextBlockLeft from "../components/sections/TextBlockLeft";
    import HomeHero from "../components/sections/HomeHero";
    import WhoWeAre from "../components/sections/WhoWeAre";
    import StatisticsBlock from "../components/sections/StatisticsBlock";
    import LatestNews from "../components/sections/LatestNews";
    import TextBlockRight from "../components/sections/TextBlockRight";
    import ManagementInfo from "../components/sections/ManagementInfo";
    import StaffList from "../components/sections/StaffList";
    import ContactInfo from "../components/sections/ContactInfo";
    import MapView from "../components/sections/MapView";
    import ReportsView from "../components/sections/ReportsView";
    import Loading from "./Loading";
    import ContactForm from "../components/sections/ContactForm";
    import SponsorshipForm from "../components/sections/SponsorshipForm";
    import Vacancies from "../components/sections/Vacancies";
    import LoadingFullscreen from "./LoadingFullscreen";
    import LawDownload from "../components/sections/LawDownload";
    import QuoteSlider from "../components/sections/QuoteSlider";
    import NewsList from "../components/sections/NewsList";
    import DonatePage from "../components/sections/DonatePage";

    export default {
        name: "PageView",
        components: {
            Loading, LoadingFullscreen,
            TheFooter, NavBar, NotFound,
            PageHeader,
            TextBlockBasic,
            ContactPersons,
            InfoBlocksFirst,
            InfoBlocksSecond,
            InfoBlocksThird,
            InfoBlocksFourth,
            SponsorInfo,
            SponsorList,
            SponsorshipPackages,
            TabsView,
            TextBlockLeft,
            TextBlockRight,
            HomeHero,
            WhoWeAre,
            StatisticsBlock,
            LatestNews,
            StaffList,
            ManagementInfo,
            ContactInfo,
            MapView,
            ReportsView,
            ContactForm,
            SponsorshipForm,
            Vacancies,
            LawDownload,
            QuoteSlider,
            NewsList,
            DonatePage
        },
        data() {
            return {
                isError: false,
                isLoading: true,
                page: {},
                blocks: [],
                subs: [],
                parentBlocks: [],
                parentSubs: []
            }
        },
        computed: {
            slug() {
                return this.$route.params.slug||'home';
            },
            languageCode() {
                return this.$route.params.language||'az';
            },
        },
        methods: {

            getCurrentPage() {
                getPage(this.slug, this.languageCode).then(({code, page, blocks, subs, parentBlocks, parentSubs}) => {

                    if (code === 200) {
                        this.page = page;
                        this.blocks = blocks;
                        this.subs = subs;
                        this.parentBlocks = parentBlocks;
                        this.parentSubs = parentSubs;
                        this.isLoading = false;
                        this.isError = false;

                        window.document.title = page.title;
                        this.$store.dispatch('app/setFrontLanguage', page.language);
                        localStorage.setItem('frontLanguageCode',page.language.code);



                    } else {
                        this.isError = true;
                        this.isLoading = false;
                        // this.$router.push('/notFound');
                    }

                })
            }

        },
        watch: {
            '$route'() {
                this.getCurrentPage();
                window.scrollTo(0, 0);
            }
        },

        created() {
            this.getCurrentPage();
        }
    }
</script>

<style lang="scss" scoped>

    .page-navigation {
        padding-top: 50px;

        .header {
            background: url("../assets/img/tab-view-header.png");
            background-size: 100% 100%;
            padding: 10px;
            margin-bottom: 50px;

            .button {
                font-size: 20px;
                text-align: center;
                cursor: pointer;
            }

            @media (max-width: 576px) {
                background: none;
                display: block;

                .button{
                    &.white{
                        background-image: url("../assets/img/sponsor-bg.png");
                    }
                    margin-bottom: 10px;
                }

            }
        }


    }

</style>