<template>
    <div v-if="requestInProgress">
        <div class="text-center text-warning lead">
            {{translations.request_form_sending}}
        </div>
    </div>
    <div v-else-if="subscribtionSuccess">
        <div class="text-center alert alert-success fw-bold lead">
            {{translations.request_form_success}}
        </div>
    </div>
    <div v-else>
        <form @submit.prevent="subscribeToNewsletter()" class="newsletter-form">
            <input type="email" class="newsletter-input" :placeholder="translations.subscribe_placeholder" v-model="subscriberEmail" required>
            <button type="submit" class="button orange">
                {{translations.subscribe_button}}
            </button>
        </form>
    </div>

</template>

<script>
    import {subscribe} from "../repositories/GeneralDataRepository";
    import {mapState} from "vuex";

    export default {
        name: "SubscribeBlock",
        data() {
            return {
                requestInProgress: false,
                subscribtionSuccess: false,
                subscriberEmail: ''
            }
        },
        computed:{
            ...mapState({
                translations: state=> state.app.translations
            })
        },
        methods: {
            subscribeToNewsletter() {
                this.requestInProgress = true;
                subscribe(this.subscriberEmail).then(data => {
                    this.requestInProgress = false;
                    if (data.code === 200) {
                        this.subscribtionSuccess = true;
                        this.subscriberEmail = '';
                    } else {
                        alert(data.error)
                    }
                })

            }
        },
    }
</script>

<style lang="scss" scoped>

    .newsletter-form {
        position: relative;
        width: 100%;
        text-align: center;

        .newsletter-input {
            border: 1px solid #E0E0E0;
            border-radius: 10px;
            padding: 10px;
            font-size: 20px;
            width: 100%;
            color: #828282;
            margin-bottom: 20px;

            &:focus {
                border: 1px solid #E0E0E0;
                outline: none;
            }

            &:focus-visible {
                border: 1px solid #E0E0E0;
                outline: none;
            }

            &::placeholder { /* Chrome, Firefox, Opera, Safari 10.1+ */
                color: #E0E0E0;
                opacity: 1; /* Firefox */
            }

            &:-ms-input-placeholder { /* Internet Explorer 10-11 */
                color: #E0E0E0;
            }

            &::-ms-input-placeholder { /* Microsoft Edge */
                color: #E0E0E0;
            }
        }

        .button {
            font-weight: normal;
            padding: 7px 15px;
            font-size: 20px;
        }
    }
</style>