<template>


    <div class="mb-2 form-floating">
        <input type="text" class="form-control" id="inputTitle" :value="inputData.title"
               @input="$emit('dataChange', {key: 'title', val: $event.target.value})">
        <label for="inputTitle">Title</label>
    </div>


    <h3>Vacancies</h3>

    <div class="row">
        <div class="col-lg-4 col-md-6  mb-3" v-for="(s,index) in inputData.vacancies" :key="index">
            <div class="card">
                <div class="card-header d-flex justify-content-between align-items-center">
                    <button type="button" class="btn btn-sm btn-danger" @click.prevent="deleteItem(index)">delete
                    </button>
                    <button type="button" class="btn btn-sm btn-outline-dark">
                        <font-awesome-icon icon="arrow-up" size="sm"/>
                    </button>
                    <button type="button" class="btn btn-sm btn-outline-dark">
                        <font-awesome-icon icon="arrow-down" size="sm"/>
                    </button>
                </div>
                <div class="card-body">
                    <div class="form-floating mb-2">
                        <input type="text" class="form-control" :value="s.title"
                               @input="$emit('dataChange', {list: 'vacancies', index: index,  key: 'title', val: $event.target.value})">
                        <label>Title</label>
                    </div>
                    <div class="form-floating mb-2">
                        <input type="text" class="form-control" :value="s.subtitle"
                               @input="$emit('dataChange', {list: 'vacancies', index: index,  key: 'subtitle', val: $event.target.value})">
                        <label>Subtitle</label>
                    </div>
                    <div class="mb-3">
                        <label class="form-label">Text</label>
                        <QuillEditor theme="snow" :options="editorOptions" :content="s.text" contentType="html"
                                     @update:content="this.$emit('dataChange', {list: 'vacancies', index: index, key: 'text', val: $event})"/>
                    </div>
                </div>
            </div>
        </div>
    </div>

    <div class="my-3">
        <button type="button" class="btn btn-outline-dark" @click.prevent="addNewItem">Add new vacancy</button>
    </div>


</template>

<script>

    import {QuillEditor} from "@vueup/vue-quill";

    export default {
        name: "VacanciesAdmin",
        components: {QuillEditor},
        props: ['inputData'],
        emits: ['dataChange','dataDelete'],

        data() {
            return {
                editorOptions: {
                    modules: {
                        toolbar: [
                            ['bold', 'italic', 'underline', 'strike'],
                            [{list: 'ordered'}, {list: 'bullet'}],
                            [{script: 'sub'}, {script: 'super'}],
                            [{header: [1, 2, 3, 4, 5, 6, false]}],
                            [
                                {color: ['#00A0E9', '#DE7A2F', '#D55163', '#86B462', '#000000', '#ffffff']},
                                {background: ['#00A0E9', '#DE7A2F', '#D55163', '#86B462', '#000000', '#ffffff']}
                            ],
                            ['clean'],
                            ['link']
                        ]
                    }
                }
            }
        },
        methods: {
            addNewItem() {
                let newIndex = 0;
                try {
                    newIndex = this.inputData.vacancies.length;
                } catch (e) {
                    console.log(e);
                }
                this.$emit('dataChange', {list: "vacancies", index: newIndex, key: 'text', val: ''})
            },
            deleteItem(index) {
                this.$emit('dataDelete', {list: "vacancies", index: index})
            }
        }
    }
</script>

<style lang="scss" scoped>
</style>