<template>
    <nav-bar/>

    <div>


        <div class="payment-block">

            <div class="hero">
                <div class="container">
                    <div class="header" v-html="translations.donation_page_title"></div>
                </div>
            </div>
            <div class="body-container ">
                <div class="container">
                    <div class="body">
                        <div class="thank-you" v-if="result ==='check'">
                            {{translations.payment_result_loading}}
                        </div>
                        <div class="thank-you" v-else-if="result ==='success'">

                            {{translations.payment_result_success}}
                        </div>
                        <div class="thank-you" v-else>
                            {{translations.payment_result_error}}
                        </div>

                    </div>
                </div>
            </div>
        </div>


    </div>
    <the-footer/>

</template>

<script>
    import {mediaBase} from '../repositories/Repository';

    import NavBar from "../components/NavBar";
    import TheFooter from "../components/TheFooter";

    import {mapState} from "vuex";
    import {getPaymentResult} from "../repositories/GeneralDataRepository";

    export default {
        name: "PaymentResult",
        components: {NavBar, TheFooter},
        data() {
            return {
                mediaBase: mediaBase,
                apiResponse: ''
            }
        },
        computed: {
            ...mapState({
                translations: state => state.app.translations
            }),
            result() {
                if(this.apiResponse==='') {
                    return this.$route.params.result;
                } else {
                    return this.apiResponse;
                }
            }
        },
        methods: {},

        mounted() {

            if (this.result === 'check') {

                let ref = localStorage.getItem("paymentReferenceId");

                getPaymentResult(ref).then(res=>{

                    if(res){
                        this.apiResponse = 'success';
                        this.$router.replace({params: {result: 'success'}})
                    } else {
                        this.apiResponse = 'error';
                        this.$router.replace({params: {result: 'error'}})
                    }

                })


            }

        }

    }
</script>

<style lang="scss" scoped>


    .payment-block {


        .hero {

            background: url("../assets/img/donate.png") center no-repeat;
            background-size: cover;
            padding-top: 350px;


            @media screen and (max-width: 500px) {
                padding-top: 150px;
            }


            .header {
                background: #DE7A2F;
                text-align: center;
                color: white;
                font-size: 40px;
                line-height: 1;
                font-weight: bold;
                padding: 20px;

                border-radius: 80px 80px 0 0;
                border: 5px solid #ffffff;
                overflow: hidden;
                position: relative;
                width: 100%;
                max-width: 600px;
                margin: 0 auto;


                @media (max-width: 576px) {
                    font-size: 24px;
                }

            }
        }

        .body-container {
            background: url("../assets/img/logo-bg-tile.png");
            padding-bottom: 100px;

            .body {
                padding: 20px;
                width: 100%;
                max-width: 600px;
                margin: 0 auto;
                box-shadow: 10px 10px 30px rgba(0, 0, 0, 0.5);

                border-radius: 0 0 80px 80px;
                background: #ffffff;
                overflow: hidden;

                .thank-you {
                    font-size: 30px;
                    color: #DE7A2F;
                    text-align: center;
                    line-height: 2;
                }

            }
        }
    }
</style>