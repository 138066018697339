<template>

    <h3>Tabs</h3>

    <div class="row">
        <div class="col-lg-4 col-md-6  mb-3" v-for="(s,index) in inputData.tabs" :key="index">
            <div class="card">
                <div class="card-header d-flex justify-content-between align-items-center">
                    <button type="button" class="btn btn-sm btn-danger" @click.prevent="deleteItem(index)">delete
                    </button>
                    <button type="button" class="btn btn-sm btn-outline-dark">
                        <font-awesome-icon icon="arrow-up" size="sm"/>
                    </button>
                    <button type="button" class="btn btn-sm btn-outline-dark">
                        <font-awesome-icon icon="arrow-down" size="sm"/>
                    </button>
                </div>
                <div class="card-body">
                    <div class="mb-3">
                        <label class="form-label">Photo</label>
                        <file-upload :file-path="s.photo" @changed="blockPhotoChanged($event,index)"/>
                    </div>
                    <div class="form-floating mb-2">
                        <input type="text" class="form-control" :value="s.title"
                               @input="$emit('dataChange', {list: 'tabs', index: index,  key: 'title', val: $event.target.value})">
                        <label>Title</label>
                    </div>
                    <div class="mb-3">
                        <label class="form-label">Text</label>
                        <QuillEditor theme="snow" :options="editorOptions" :content="s.text" contentType="html"
                                     @update:content="this.$emit('dataChange', {list: 'tabs', index: index, key: 'text', val: $event})"/>
                    </div>


                    <div class="form-floating mb-2">
                        <select class="form-control" :value="s.design"
                                @input="$emit('dataChange', {list: 'tabs', index: index,  key: 'design', val: $event.target.value})">
                            <option value="vertical">Vetical</option>
                            <option value="horizontal-left">Horizontal (photo left)</option>
                            <option value="horizontal-right">Horizontal (photo right)</option>
                        </select>
                        <label>Design</label>
                    </div>
                </div>
            </div>
        </div>
    </div>

    <div class="my-3">
        <button type="button" class="btn btn-outline-dark" @click.prevent="addNewItem">Add new tab</button>
    </div>


</template>

<script>

    import FileUpload from "../FileUpload";

    import {QuillEditor} from '@vueup/vue-quill'
    import '@vueup/vue-quill/dist/vue-quill.snow.css';


    export default {
        name: "TabsViewAdmin",
        components: {FileUpload, QuillEditor},
        data() {
            return {
                editorOptions: {
                    modules: {
                        toolbar: [
                            ['bold', 'italic', 'underline', 'strike'],
                            [{list: 'ordered'}, {list: 'bullet'}],
                            [{script: 'sub'}, {script: 'super'}],
                            [{header: [1, 2, 3, 4, 5, 6, false]}],
                            [
                                {color: ['#00A0E9', '#DE7A2F', '#D55163', '#86B462', '#000000', '#ffffff']},
                                {background: ['#00A0E9', '#DE7A2F', '#D55163', '#86B462', '#000000', '#ffffff']}
                            ],
                            ['clean'],
                            ['link']
                        ]
                    }
                }
            }
        },
        props: ['inputData'],
        emits: ['dataChange', 'dataDelete'],
        methods: {
            blockPhotoChanged(data, index) {
                this.$emit('dataChange', {list: "tabs", index: index, key: 'photo', val: data})
            },
            addNewItem() {
                let newIndex = 0;
                try {
                    newIndex = this.inputData.tabs.length;
                } catch (e) {
                    console.log(e);
                }
                this.$emit('dataChange', {list: "tabs", index: newIndex, key: 'photo', val: ''})
            },
            deleteItem(index) {
                this.$emit('dataDelete', {list: "tabs", index: index})
            }
        }
    }
</script>

<style lang="scss" scoped>
</style>