<template>


    <div class="mb-3">
        <label class="form-label">Text</label>
        <QuillEditor theme="snow" toolbar="minimal" :content="inputData.text" contentType="html"
                     @update:content="this.$emit('dataChange', {key: 'text', val: $event})"/>
    </div>

    <div class="mb-3">
        <label class="form-label">Photo</label>
        <file-upload :file-path="inputData.background"
                     @changed="photoChanged"/>
    </div>


    <div class="mb-3 form-floating">
        <input type="text" class="form-control" :value="inputData.video"
               @input="$emit('dataChange', {key: 'video', val: $event.target.value})">
        <label >Youtube video link</label>
    </div>

</template>

<script>

    import {QuillEditor} from '@vueup/vue-quill'
    import '@vueup/vue-quill/dist/vue-quill.snow.css';

    import FileUpload from "../FileUpload";

    export default {
        name: "TextBlockRightAdmin",
        components: {FileUpload, QuillEditor},
        props: ['inputData'],
        emits: ['dataChange'],
        methods: {
            photoChanged(data) {
                this.$emit('dataChange', {key: 'photo', val: data})
            }
        }
    }
</script>

<style lang="scss" scoped>
</style>