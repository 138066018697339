<template>
    <nav-bar/>
    <div v-if="isLoading" class="">
        <loading/>
    </div>
    <div v-else-if="isError">
        <not-found/>
    </div>
    <div v-else>
        <PageHeader :data="header"></PageHeader>

        <div class="container">

            <div class="row my-5">
                <div class="col-md-9">
                    <img :src="`${mediaBase}${news.photo}`" class="news-photo" v-if="news.photo">

                    <div class="my-4 lead news-text" v-html="news.data"></div>
                </div>
                <div class="col-md-3">

                    <category-cloud :active="news.category.id" @categoryClicked="goToCategory($event)"/>


                    <div class="latest mt-5">

                        <div class="title">
                            {{translations.news_page_title}}
                        </div>

                        <div class="news-list">
                            <div class="news-item" v-for="n in latest" :key="n.id">
                                <router-link :to="`/post/${n.slug}`">
                                    <strong>{{n.title}}</strong> <br>
                                    <i>{{n.logTime.date.split(' ')[0]}}</i>
                                </router-link>
                            </div>
                        </div>

                    </div>

                </div>
            </div>

            <div class="my-5">
                <div class="w-25 mx-auto">
                    <subscribe-block/>
                </div>

            </div>
        </div>
    </div>

    <the-footer/>
</template>

<script>
    import {mediaBase} from '../repositories/Repository';
    import {getNews, getNewsList} from "../repositories/GeneralDataRepository";

    import PageHeader from "../components/sections/PageHeader";
    import NotFound from "./NotFound";
    import NavBar from "../components/NavBar";
    import TheFooter from "../components/TheFooter";
    import CategoryCloud from "../components/CategoryCloud";
    import SubscribeBlock from "../components/SubscribeBlock";
    import Loading from "./Loading";
    import {mapState} from "vuex";

    export default {
        name: "NewsPost",
        components: {
            Loading,
            SubscribeBlock,
            CategoryCloud,
            TheFooter, NavBar, NotFound,
            PageHeader
        },
        data() {
            return {
                mediaBase: mediaBase,
                isError: false,
                isLoading: true,
                news: {},
                latest: [],
                languageCode: ''
            }
        },
        computed: {
            ...mapState({
                translations: state => state.app.translations,
                settings: state => state.app.settings
            }),
            slug() {
                return this.$route.params.slug;
            },
            header() {
                return {
                    title: this.news.title,
                    background: this.news.photo
                }
            },
        },
        methods: {

            getCurrentPage() {
                getNews(this.slug).then(({code, news}) => {

                    if (code === 200) {
                        this.news = news;
                        this.isLoading = false;
                        this.isError = false;

                    } else {
                        this.isError = true;
                        this.isLoading = false;
                    }

                })
            },

            goToCategory(categoryId) {
                this.$router.push(`/${this.languageCode}/${this.settings.news_list_slug}?cat=${categoryId}`);
            }

        },
        watch: {
            '$route'() {
                this.getCurrentPage();
                window.scrollTo(0, 0);
            }
        },

        created() {
            this.getCurrentPage();

            getNewsList(1, 3).then(d => {

                if (d.code === 200) {
                    this.latest = d.news;
                }

            });

            this.languageCode =  localStorage.getItem('frontLanguageCode');
        }
    }
</script>

<style lang="scss" scoped>


    .news-photo {
        width: 100%;
        border-radius: 60px;
    }

    .latest {

        .title {
            color: #00A0E9;
            font-size: 40px;
            font-weight: bold;
        }

        .news-list {

            .news-item {
                margin-bottom: 20px;
                font-size: 17px;

                a {
                    text-decoration: none;
                }

                strong {
                    color: #4F4F4F;
                }

                i {
                    color: #828282;
                }
            }
        }

    }

    .news-text {

      ::v-deep iframe{
            width: 100%;
            height: 400px;
        }

      ::v-deep img{
            width: 100%;
            height: auto;
        }

    }

    .w-25 {

        @media (max-width: 576px) {
            width: 90% !important;
        }
    }
</style>
