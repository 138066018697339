<template>

    <section class="contact-form">

        <div class="container">

            <div class="w-75 mx-auto">
                <form @submit.prevent="submitContactForm()">
                    <div class="row">
                        <div class="col-md-4 mb-3">
                            <input type="text" class="form-control form-control-lg" :placeholder="translations.contact_form_name" required
                                   v-model="contactData.name">
                        </div>
                        <div class="col-md-4 mb-3">
                            <input type="tel" class="form-control form-control-lg" :placeholder="translations.contact_form_phone" required
                                   v-model="contactData.phone">
                        </div>
                        <div class="col-md-4 mb-3">
                            <input type="email" class="form-control form-control-lg" :placeholder="translations.contact_form_email" required
                                   v-model="contactData.email">
                        </div>
                        <div class="col-12 mb-3">
                            <select class="form-control form-control-lg" required v-model="contactData.subject">
                                <option v-for="s in data.subjects" :key="s.text" :value="s.text">{{s.text}}</option>
                            </select>
                        </div>
                        <div class="col-12 mb-3">
                            <textarea class="form-control form-control-lg" rows="5" required v-model="contactData.message"></textarea>
                        </div>
                        <div class="col-12 mb-3 text-center" v-if="!requesting">
                            <button class="button orange">{{translations.contact_form_button}}</button>
                        </div>
                        <div class="mb-3 alert alert-info text-center" v-else>
                            {{translations.request_form_sending}}
                        </div>
                        <div class="mb-3 alert alert-danger text-center" v-if="isError">
                            {{translations.request_form_error}}
                        </div>
                        <div class="mb-3 alert alert-success text-center" v-if="isSuccess">
                            {{translations.request_form_success}}
                        </div>

                    </div>
                </form>
            </div>
        </div>

    </section>

</template>

<script>
    import {mediaBase} from "../../repositories/Repository";
    import {mapState} from "vuex";

    export default {
        name: "ContactInfo",
        props: ['data'],
        data() {
            return {
                mediaBase: mediaBase,
                contactData: {
                    name: '',
                    phone: '',
                    email: '',
                    subject: '',
                    message: ''
                },
                requesting: false,
                isSuccess: false,
                isError: false
            }
        },
        computed:{
            ...mapState({
                translations: state=> state.app.translations
            })
        },
        methods: {
            submitContactForm() {

                console.log(this.contactData);
                this.requesting = true;

                setTimeout(() => {
                    this.requesting = false;
                    this.isError = true;
                    setTimeout(() => {
                        this.isError = false;
                        this.isSuccess = true;

                        this.contactData = {
                            name: '',
                            phone: '',
                            email: '',
                            subject: '',
                            message: ''
                        }

                    }, 2000)
                }, 2000)

            }
        }
    }
</script>

<style lang="scss" scoped>
    .contact-form {
        padding: 50px 0;

        .title {
            font-size: 40px;
            line-height: 50px;
            color: #00A0E9;
            text-align: center;
            margin-bottom: 50px;

          ::v-deep span {
                color: #DE7A2F !important;
            }
        }


        .form-control {
            background: #F2F2F2;
            color: #BDBDBD;


            &::placeholder { /* Chrome, Firefox, Opera, Safari 10.1+ */
                color: #BDBDBD;
                opacity: 1; /* Firefox */
            }

            &:-ms-input-placeholder { /* Internet Explorer 10-11 */
                color: #BDBDBD;
            }

            &::-ms-input-placeholder { /* Microsoft Edge */
                color: #BDBDBD;
            }
        }


    }
</style>
