<template>

    <section class="tabs-view">

        <div class="container">
            <div class="header d-block d-md-flex">

                <div class="col" v-for="(item, index) in data.tabs" :key="index">
                    <button class="button block" @click="currentIndex = index"
                            :class="{'orange': index !== currentIndex, 'white': index === currentIndex}">
                        {{item.title}}
                    </button>
                </div>
            </div>


            <div class="content">

                <div class="row" v-if="currentItem.design === 'horizontal-left'">
                    <div class="col-md-5">
                        <img v-if="currentItem.photo" :src="`${mediaBase}${currentItem.photo}`" class="img-fluid mb-3"
                             :alt="currentItem.title">
                    </div>
                    <div class="col-md-7">
                        <div v-html="currentItem.text"></div>
                    </div>
                </div>
                <div class="row" v-else-if="currentItem.design === 'horizontal-right'">
                    <div class="col-md-7">
                        <div v-html="currentItem.text"></div>
                    </div>
                    <div class="col-md-5">
                        <img v-if="currentItem.photo" :src="`${mediaBase}${currentItem.photo}`" class="img-fluid mb-3"
                             :alt="currentItem.title">
                    </div>
                </div>
                <div v-else>
                    <img v-if="currentItem.photo" :src="`${mediaBase}${currentItem.photo}`" class="img-fluid mb-3"
                         :alt="currentItem.title">
                    <div v-html="currentItem.text"></div>
                </div>


            </div>
        </div>

    </section>

</template>

<script>
    import {mediaBase} from "../../repositories/Repository";

    export default {
        name: "TabsView",
        props: ['data'],
        data() {
            return {
                currentIndex: 0,
                mediaBase: mediaBase
            }
        },
        computed: {
            currentItem() {
                return this.data.tabs[this.currentIndex];
            }
        }
    }
</script>

<style lang="scss" scoped>
    .tabs-view {
        padding: 50px 0;

        .header {
            background: url("../../assets/img/tab-view-header.png");
            background-size: 100% 100%;
            padding: 10px;
            margin-bottom: 50px;

            .button {
                font-size: 20px;
                cursor: pointer;
            }


            @media (max-width: 576px) {
                background: none;

                .button{
                    &.white{
                        background-image: url("../../assets/img/sponsor-bg.png");
                    }
                    margin-bottom: 10px;
                }

            }

        }


        .content {
            font-size: 20px;
            line-height: 25px;

            @media (max-width: 576px) {
                font-size: 14px;
            }

          ::v-deep {
                h2 {
                    display: block;
                    color: #00A0E9;
                    font-weight: bold;
                    font-size: 30px;
                    margin-bottom: 15px;

                    @media (max-width: 576px) {
                        font-size: 24px;
                    }
                }
            }

        }


    }
</style>
