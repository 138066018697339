<template>

    <div>

        <input type="hidden" class="form-control" :value="filePath"
               @input="$emit('dataChange', {key: 'background', val: $event.target.value})">


        <div class="mb-2">
            <label class="d-inline-block me-2">
                <a class="btn btn-sm btn-primary">Select file</a>
                <input class="form-control" type="file" ref="file" @change="upload" style="display: none"/>
            </label>

            <button v-if="photoPath" class="btn btn-sm btn-warning" @click.prevent="this.photoPath=''">remove</button>
        </div>

        <div v-if="currentFile" class="progress">
            <div
                    class="progress-bar progress-bar-info progress-bar-striped"
                    role="progressbar"
                    :aria-valuenow="progress"
                    aria-valuemin="0"
                    aria-valuemax="100"
                    :style="{ width: progress + '%' }"
            >
                {{ progress }}%
            </div>
        </div>
        <div v-if="message" class="text-danger">{{message}}</div>
        <div v-if="photoPath">
            <img v-if="['jpg','png','gif'].indexOf(photoPath.split('.').pop())>-1" alt="photo"
                 :src="`${mediaBase}${photoPath}`" class="img-thumbnail img-fluid"
                 style="max-height: 150px;"/>
            <video v-else-if="['mp4'].indexOf(photoPath.split('.').pop())>-1" alt="photo"
                   :src="`${mediaBase}${photoPath}`" class="img-thumbnail img-fluid"
                   style="max-height: 150px;" controls></video>
            <div v-else>
                <div>
                    <strong>*.{{photoPath.split('.').pop()}}</strong> file
                    <a :href="`${mediaBase}${photoPath}`" target="_blank">download</a>
                </div>
            </div>
        </div>

    </div>

</template>

<script>
    import {upload} from "../../repositories/AdminRepository";
    import {mediaBase} from '../../repositories/Repository';

    export default {
        name: "FileUpload",
        props: ['filePath'],
        emits: ['changed'],
        data() {
            return {
                mediaBase: mediaBase,
                photoPath: this.filePath,
                currentFile: undefined,
                progress: 0,
                message: "",
            };
        },
        methods: {
            upload() {
                this.progress = 0;
                this.message = "";

                this.currentFile = this.$refs.file.files[0];
                upload(this.currentFile, event => {
                    this.progress = Math.round((100 * event.loaded) / event.total);
                })
                    .then(response => {
                        this.message = response.data.message;
                        if (response.data.code === 200) {
                            this.photoPath = response.data.path;
                        } else {
                            this.message = response.data.error;
                        }
                        this.currentFile = undefined;
                    })
                    .catch((e) => {
                        console.log(e);
                        this.progress = 0;
                        this.message = "Could not upload the file! " + e;
                        this.currentFile = undefined;
                    });
            }
        }, watch: {
            photoPath() {
                this.$emit('changed', this.photoPath);
            }
        }
    }
</script>

<style scoped>

</style>