<template>


    <div class="mb-2 form-floating">
        <input type="text" class="form-control" id="inputTitle" :value="inputData.title"
               @input="$emit('dataChange', {key: 'title', val: $event.target.value})">
        <label for="inputTitle">Title</label>
    </div>

    <h3>Packages</h3>

    <div class="row">
        <div class="col-md-6  mb-3" v-for="(s,index) in inputData.packages" :key="index">
            <div class="card">
                <div class="card-header d-flex justify-content-between align-items-center">
                    <button type="button" class="btn btn-sm btn-danger" @click.prevent="deleteItem(index)">delete
                    </button>
                    <button type="button" class="btn btn-sm btn-outline-dark">
                        <font-awesome-icon icon="arrow-up" size="sm"/>
                    </button>
                    <button type="button" class="btn btn-sm btn-outline-dark">
                        <font-awesome-icon icon="arrow-down" size="sm"/>
                    </button>
                </div>
                <div class="card-body">
                    <div class="form-floating mb-2">
                        <input type="text" class="form-control" :value="s.name"
                               @input="$emit('dataChange', {list: 'packages', index: index,  key: 'name', val: $event.target.value})">
                        <label>Name</label>
                    </div>
                    <div class="form-floating mb-2">
                        <select type="text" class="form-control" :value="s.color"
                               @input="$emit('dataChange', {list: 'packages', index: index,  key: 'color', val: $event.target.value})">
                            <option value="platinum">platinum</option>
                            <option value="gold">gold</option>
                            <option value="silver">silver</option>
                            <option value="bronze">bronze</option>
                        </select>
                        <label>Color</label>
                    </div>
                    <div class="form-floating mb-2">
                        <input type="number" class="form-control" :value="s.price"
                               @input="$emit('dataChange', {list: 'packages', index: index,  key: 'price', val: $event.target.value})">
                        <label>Amount</label>
                    </div>
                    <div class="form-floating mb-2">
                        <textarea style="height: 100px" class="form-control" :value="s.features"
                               @input="$emit('dataChange', {list: 'packages', index: index,  key: 'features', val: $event.target.value})"></textarea>
                        <label>Features (1 item per line)</label>
                    </div>
                    <div class="form-floating mb-2">
                        <input type="text" class="form-control" :value="s.buttonText"
                               @input="$emit('dataChange', {list: 'packages', index: index,  key: 'buttonText', val: $event.target.value})">
                        <label>Button text</label>
                    </div>
                </div>
            </div>
        </div>
    </div>

    <div class="my-3">
        <button type="button" class="btn btn-outline-dark" @click.prevent="addNewItem">Add new package</button>
    </div>


</template>

<script>


    export default {
        name: "SponsorshipPackagesAdmin",
        props: ['inputData'],
        emits: ['dataChange','dataDelete'],
        methods: {
            addNewItem() {
                let newIndex = 0;
                try {
                    newIndex = this.inputData.packages.length;
                } catch (e) {
                    console.log(e);
                }
                this.$emit('dataChange', {list: "packages", index: newIndex, key: 'title', val: ''})
            },
            deleteItem(index) {
                this.$emit('dataDelete', {list: "packages", index: index})
            }
        }
    }
</script>

<style lang="scss" scoped>
</style>