<template>
    <section class="sponsors">

        <div class="container">

            <div v-if="screenSize>768">
                <div class="row mb-5" v-for="(s,i) in data.sponsors" :key="s.id">

                    <div class="col-md-6  border-3 border-secondary d-flex justify-content-center align-items-center mb-4"
                         :class="{'order-md-1': i%2===0, 'order-md-2': i%2===1, 'border-start':  i%2===1,  'border-end':  i%2===0,  }">
                        <img class="logo" :src="`${mediaBase}${s.photo}`" alt="sponsor">
                    </div>
                    <div class="col-md-6 mb-4  order-2" :class="{'order-md-2': i%2===0, 'order-md-1': i%2===1, }">
                        <div class="description" v-html="s.text"></div>
                    </div>

                </div>
            </div>

            <div v-else>

                <div class="position-relative">

                    <div class="swiper-button-prev stats-prev"></div>
                    <div class="swiper-button-next stats-next"></div>

                    <swiper :slides-per-view="1" :autoplay="{delay: 5000,}" :space-between="0" :pagination="true"
                            :navigation="{ nextEl: '.stats-next', prevEl: '.stats-prev', }" :autoHeight="true">
                        <swiper-slide v-for="(s) in data.sponsors" :key="s.id">

                            <div class="px-5">
                                <img class="logo" :src="`${mediaBase}${s.photo}`" alt="sponsor">
                            </div>
                            <div>
                                <div class="description pb-4" v-html="s.text"></div>
                            </div>

                        </swiper-slide>

                    </swiper>
                </div>

            </div>

        </div>

    </section>
</template>

<script>
    import {mediaBase} from "../../repositories/Repository";

    import SwiperCore, {A11y, Navigation, Pagination} from 'swiper';
    import {Swiper, SwiperSlide} from 'swiper/vue';

    SwiperCore.use([A11y, Navigation, Pagination]);
    // Import Swiper styles
    import "swiper/swiper-bundle.css";

    export default {
        name: "SponsorInfo",
        props: ["data"],
        components: {Swiper, SwiperSlide},
        data() {
            return {
                mediaBase: mediaBase,
                screenSize: 0
            };
        },

        mounted() {
            this.screenSize = window.innerWidth
        }
    }
</script>

<style lang="scss" scoped>

    .sponsors {
        padding: 50px 0;


        img.logo {
            width: 100%;
        }

        .description {
            font-size: 20px;
            line-height: 30px;
            padding: 10px 40px;
            text-align: justify;
        }

        .swiper-button-prev, .swiper-button-next {
            font-size: 15px !important;
            color: #717070;
            top: 50px;
            &:after{
                font-size: 25px !important;
            }
        }

      ::v-deep .swiper-pagination-bullets{
            bottom: 0;
        }

    }

</style>
