<template>

    <section class="info-blocks">

        <div class="container">

            <div class="title" v-if="data.title" v-html="data.title"></div>

            <div class="row justify-content-center" v-if="screenSize > 768">

                <div class="col-md col-6 mb-5" v-for="(b,i) in data.blocks" :key="i">

                    <div class=" info-block">
                        <img class="icon" :src="`${mediaBase}${b.photo}`" :alt="b.title">
                        <div class="title">{{b.title}}</div>
                        <div class="text">{{b.text}}</div>
                    </div>

                </div>

            </div>
            <div class="position-relative px-5" v-else>

                <div class="swiper-button-prev block3-prev"></div>
                <div class="swiper-button-next block3-next"></div>

                <swiper :slides-per-view="1" :autoplay="{delay: 5000,}" :space-between="0"
                        :navigation="{ nextEl: '.block3-next', prevEl: '.block3-prev', }">
                    <swiper-slide v-for="(b,i) in data.blocks" :key="i">
                        <div class=" info-block">
                            <img class="icon" :src="`${mediaBase}${b.photo}`" :alt="b.title">
                            <div class="title">{{b.title}}</div>
                            <div class="text">{{b.text}}</div>
                        </div>
                    </swiper-slide>

                </swiper>
            </div>

        </div>

    </section>

</template>

<script>
    import {mediaBase} from "../../repositories/Repository";
    import SwiperCore, {A11y, Navigation} from 'swiper';
    import {Swiper, SwiperSlide} from 'swiper/vue';

    import 'swiper/swiper.scss';

    SwiperCore.use([A11y, Navigation]);
    // Import Swiper styles
    import "swiper/swiper-bundle.css";


    export default {
        name: "InfoBlocksThird",
        props: ['data'],
        components: {Swiper, SwiperSlide},
        data() {
            return {
                mediaBase: mediaBase,
                screenSize: 0
            }
        },
        mounted() {

            this.screenSize = window.innerWidth
        }
    }
</script>

<style lang="scss" scoped>
    .info-blocks {
        padding: 100px 0;


        @media (max-width: 576px) {
            padding: 50px 0;
        }

        .title {
            font-size: 40px;
            line-height: 1.25;
            color: #00A0E9;
            text-align: center;
            margin-bottom: 50px;

          ::v-deep span {
                color: #DE7A2F !important;
            }


            @media (max-width: 576px) {
                font-size: 24px;
            }
        }


        .info-block {
            text-align: center;

            img.icon {
                width: 50%;
                margin-bottom: 20px;

                @media (max-width: 576px) {
                    margin-bottom: 10px;
                }
            }

            .title {
                font-size: 35px;
                line-height: 1.25;
                margin-bottom: 10px;
                color: #00A0E9;
                font-weight: bold;


                @media (max-width: 576px) {
                    font-size: 15px;
                }
            }

            .text {
                font-size: 20px;
                color: #929292;

                @media (max-width: 576px) {
                    font-size: 14px;
                }
            }

        }


    }
</style>
