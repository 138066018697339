<template>


    <div class="mb-2 form-floating">
        <input type="text" class="form-control" id="inputTitle" :value="inputData.title"
               @input="$emit('dataChange', {key: 'title', val: $event.target.value})">
        <label for="inputTitle">Title</label>
    </div>

    <div class="mb-2 form-floating">
        <input type="text" class="form-control" id="inputSubTitle" :value="inputData.subtitle"
               @input="$emit('dataChange', {key: 'subtitle', val: $event.target.value})">
        <label for="inputSubTitle">SubTitle</label>
    </div>

    <div class="mb-2 form-floating">
        <textarea type="text" class="form-control" id="inputText" :value="inputData.text" style="height: 120px"
                  @input="$emit('dataChange', {key: 'text', val: $event.target.value})"> </textarea>
        <label for="inputText">Text</label>
    </div>

    <div class="mb-2 form-floating">
        <input type="text" class="form-control" id="inputLinkText" :value="inputData.linkText"
               @input="$emit('dataChange', {key: 'linkText', val: $event.target.value})">
        <label for="inputSubTitle">Link text</label>
    </div>


    <h3>Blocks</h3>

    <div class="row">
        <div class="col-lg-4 col-md-6  mb-3" v-for="(s,index) in inputData.blocks" :key="index">
            <div class="card">
                <div class="card-header d-flex justify-content-between align-items-center">
                    <button type="button" class="btn btn-sm btn-danger" @click.prevent="deleteItem(index)">delete
                    </button>
                    <button type="button" class="btn btn-sm btn-outline-dark">
                        <font-awesome-icon icon="arrow-up" size="sm"/>
                    </button>
                    <button type="button" class="btn btn-sm btn-outline-dark">
                        <font-awesome-icon icon="arrow-down" size="sm"/>
                    </button>
                </div>
                <div class="card-body">
                    <div class="mb-3">
                        <label class="form-label">Photo</label>
                        <file-upload :file-path="s.photo" @changed="blockPhotoChanged($event,index)"/>
                    </div>
                    <div class="form-floating mb-2">
                        <input type="text" class="form-control" :value="s.title"
                               @input="$emit('dataChange', {list: 'blocks', index: index,  key: 'title', val: $event.target.value})">
                        <label>Title</label>
                    </div>
                    <div class="form-floating mb-2">
                        <input type="text" class="form-control" :value="s.url"
                               @input="$emit('dataChange', {list: 'blocks', index: index,  key: 'url', val: $event.target.value})">
                        <label>Link</label>
                    </div>
                    <div class="form-floating mb-2">
                        <select  class="form-control" :value="s.target"
                               @input="$emit('dataChange', {list: 'blocks', index: index,  key: 'target', val: $event.target.value})">
                            <option value="_self">this page</option>
                            <option value="_blank">new page</option>
                        </select>
                        <label>Target</label>
                    </div>
                </div>
            </div>
        </div>
    </div>

    <div class="my-3">
        <button type="button" class="btn btn-outline-dark" @click.prevent="addNewItem">Add new block</button>
    </div>


</template>

<script>

    import FileUpload from "../FileUpload";

    export default {
        name: "WhoWeAreAdmin",
        components: {FileUpload},
        props: ['inputData'],
        emits: ['dataChange','dataDelete'],
        methods: {
            blockPhotoChanged(data, index) {
                this.$emit('dataChange', {list: "blocks", index: index, key: 'photo', val: data})
            },
            addNewItem() {
                let newIndex = 0;
                try {
                    newIndex = this.inputData.blocks.length;
                } catch (e) {
                    console.log(e);
                }
                this.$emit('dataChange', {list: "blocks", index: newIndex, key: 'photo', val: ''})
            },
            deleteItem(index) {
                this.$emit('dataDelete', {list: "blocks", index: index})
            }
        }
    }
</script>

<style lang="scss" scoped>
</style>