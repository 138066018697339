<template>

    <section class="info-blocks">

        <div class="container">

            <div class="title" v-if="data.title" v-html="data.title"></div>

            <div class="row justify-content-between">

                <div class="col-md-3 mb-5" v-for="(b,i) in data.blocks" :key="i">

                    <div class=" info-block" :class="b.color">
                        <div class="number">{{i+1}}</div>
                        <div class="text">{{b.text}}</div>
                    </div>

                </div>

            </div>
        </div>

    </section>

</template>

<script>
    export default {
        name: "InfoBlocksFourth",
        props: ["data"],
        data() {
            return {
            }
        }
    }
</script>

<style lang="scss" scoped>
    .info-blocks {
        padding: 50px 0;

        .title {
            font-size: 40px;
            line-height: 50px;
            color: #00A0E9;
            text-align: center;
            margin-bottom: 50px;

            @media (max-width: 576px) {
                font-size: 24px;
            }

            span {
                color: #DE7A2F !important;
            }
        }


        .info-block {
            text-align: center;
            background: url("../../assets/img/sponsor-bg.png");
            background-size: 100% 100% !important;
            padding: 15px;
            color: #929292;


            @media (max-width: 576px) {
                padding: 10px;
            }


            .number {
                display: inline-block;
                font-size: 35px;
                line-height: 60px;
                width: 60px;
                margin-top: -40px;
                color: #00A0E9;
                font-weight: bold;
                background: #ffffff;
                border-radius: 5px;
            }

            .text {
                padding: 25px;
                font-size: 20px;
            }


            &.blue {
                background-image: url("../../assets/img/bg-blue.svg");
                background-size: 100% 100% !important;
                color: #ffffff;

                .number {
                    color: #00A0E9;
                }
            }

            &.orange {
                background-image: url("../../assets/img/bg-orange.svg");
                color: #ffffff;

                .number {
                    color: #DE7A2F;
                }
            }

            &.red {
                background-image: url("../../assets/img/bg-red.svg");
                color: #ffffff;

                .number {
                    color: #D55163;
                }
            }

        }


    }
</style>