<template>
    <div class="category-cloud">

        <a v-if="showall" href="#"   @click.prevent="$emit('categoryClicked',0)"
           class="category-item" :class="{active: 0 === active}">{{translations.allArticles}}</a>

        <a v-for="c in categories" href="#" :key="c.id" @click.prevent="$emit('categoryClicked',c.id)"
           class="category-item" :class="{active:c.id === active}">{{c.name}}</a>

    </div>
</template>

<script>
    import {getCategoryList} from "../repositories/GeneralDataRepository";
    import {mapState} from "vuex";

    export default {
        name: "CategoryCloud",
        props: ['active', 'showall'],
        data() {
            return {
                categories: []
            }
        },

        computed:{
            ...mapState({
                translations: state=> state.app.translations
            })
        },
        mounted() {

            getCategoryList().then(d => {
                this.categories = d;
            })
        }
    }
</script>

<style lang="scss" scoped>

    .category-cloud {

        .category-item {
            display: inline-block;
            margin: 0 10px 10px 0;
            padding: 15px 25px;
            background: url("../assets/img/grey-bg.png") no-repeat;
            background-size: 100% 100%;
            color: #828282;
            font-weight: bold;
            text-decoration: none;


            @media (max-width: 576px) {
                font-size: 14px;
                padding: 10px 15px;
            }

            &.active {
                background-image: url("../assets/img/blue-bg.png");
                color: white;
            }
        }

    }

</style>