<template>


    <div class="mb-2 form-floating">
        <input type="text" class="form-control" id="inputTitle" :value="inputData.title"
               @input="$emit('dataChange', {key: 'title', val: $event.target.value})">
        <label for="inputTitle">Title</label>
    </div>

    <div class="mb-2 form-floating">
        <input type="text" class="form-control" :value="inputData.buttonText"
               @input="$emit('dataChange', {key: 'buttonText', val: $event.target.value})">
        <label >Button text</label>
    </div>

    <div class="mb-3">
        <label class="form-label">File</label>
        <file-upload :file-path="inputData.file" @changed="fileUploaded($event)"/>
    </div>


    <h3>Blocks</h3>

    <div class="row">
        <div class="col-lg-4 col-md-6  mb-3" v-for="(s,index) in inputData.blocks" :key="index">
            <div class="card">
                <div class="card-header d-flex justify-content-between align-items-center">
                    <button type="button" class="btn btn-sm btn-danger" @click.prevent="deleteItem(index)">delete
                    </button>
                    <button type="button" class="btn btn-sm btn-outline-dark">
                        <font-awesome-icon icon="arrow-up" size="sm"/>
                    </button>
                    <button type="button" class="btn btn-sm btn-outline-dark">
                        <font-awesome-icon icon="arrow-down" size="sm"/>
                    </button>
                </div>
                <div class="card-body">
                    <div class="form-floating mb-2">
                        <textarea type="text" class="form-control" :value="s.text" style="height: 100px;"
                               @input="$emit('dataChange', {list: 'blocks', index: index,  key: 'text', val: $event.target.value})">
                        </textarea>
                        <label>Text</label>
                    </div>
                </div>
            </div>
        </div>
    </div>

    <div class="my-3">
        <button type="button" class="btn btn-outline-dark" @click.prevent="addNewItem">Add new block</button>
    </div>


</template>

<script>

    import FileUpload from "../FileUpload";

    export default {
        name: "InfoBlocksSecondAdmin",
        components: {FileUpload},
        props: ['inputData'],
        emits: ['dataChange','dataDelete'],
        methods: {
            fileUploaded(data) {
                this.$emit('dataChange', { key: 'file', val: data})
            },
            addNewItem() {
                let newIndex = 0;
                try {
                    newIndex = this.inputData.blocks.length;
                } catch (e) {
                    console.log(e);
                }
                this.$emit('dataChange', {list: "blocks", index: newIndex, key: 'text', val: ''})
            },
            deleteItem(index) {
                this.$emit('dataDelete', {list: "blocks", index: index})
            }
        }
    }
</script>

<style lang="scss" scoped>
</style>