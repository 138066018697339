<template>

    <div class="statistics " :style="{background: data.background}">

        <div class="container">

            <div class="row" v-if="screenSize> 768">

                <div class="col-md col-sm-12 mb-4" v-for="s in data.statistics" :key="s.id">
                    <div class="statistics-item">
                        <img :src="`${mediaBase}${s.photo}`" :alt="s.title">
                        <br>
                        {{s.count}} <br>
                        {{s.title}}

                    </div>
                </div>

            </div>

            <div class="position-relative px-5" v-else>

                <div class="swiper-button-prev stats-prev"></div>
                <div class="swiper-button-next stats-next"></div>

                <swiper :slides-per-view="1" :autoplay="{delay: 5000,}" :space-between="0"
                        :navigation="{ nextEl: '.stats-next', prevEl: '.stats-prev', }">
                    <swiper-slide v-for="(s,i) in data.statistics" :key="i">
                        <div class="statistics-item">
                            <img :src="`${mediaBase}${s.photo}`" :alt="s.title">
                            <br>
                            {{s.count}} <br>
                            {{s.title}}

                        </div>
                    </swiper-slide>

                </swiper>
            </div>


        </div>

    </div>
</template>

<script>
    import {mediaBase} from "../../repositories/Repository";
    import SwiperCore, {A11y, Navigation} from 'swiper';
    import {Swiper, SwiperSlide} from 'swiper/vue';

    import 'swiper/swiper.scss';

    SwiperCore.use([A11y, Navigation]);
    // Import Swiper styles
    import "swiper/swiper-bundle.css";



    export default {
        name: "StatisticsBlock",
        props: ['data'],
        components: {Swiper, SwiperSlide},
        data() {
            return {
                mediaBase: mediaBase,
                screenSize: 0
            }
        },
        mounted() {

            this.screenSize = window.innerWidth
        }
    }
</script>

<style lang="scss" scoped>


    .statistics {
        padding: 40px 0;

        .statistics-item {
            text-align: center;
            color: #ffffff;
            font-size: 26px;
            font-weight: bold;

            img {
                height: 100px;
            }
        }
    }

    .swiper-button-prev, .swiper-button-next{
        font-size: 25px !important;
        color: #ffffff;
    }


</style>