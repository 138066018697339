<template>

    <section class="text-block">

        <div class="container">

            <div class="row">


                <div class="col-md-6">
                    <div class="media-holder">
                        <img :src="`${mediaBase}${data.photo}`" class="img-fluid" v-if="!showVideo">
                        <a target="_blank" class="video-play-button" v-if="data.video!=='' && !showVideo"
                           :href="data.video" @click.prevent="showVideo = true"></a>

                        <iframe v-if="showVideo" class="video-embed" :src="getVideoEmbedPath"
                                title="YouTube video player" frameborder="0"
                                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                                allowfullscreen></iframe>
                    </div>
                </div>

                <div class="col-md-6 d-flex align-items-center">
                    <div class="content">
                        <h3 class="title" v-html="data.title" v-if="data.title"></h3>

                        <div v-html="data.text"> </div>
                    </div>
                </div>

            </div>

        </div>

    </section>

</template>

<script>
    import {mediaBase} from "../../repositories/Repository";

    export default {
        name: "TextBlockRight",
        props: ['data'],
        data() {
            return {
                mediaBase: mediaBase,
                showVideo: false
            }
        },
        computed: {
            getVideoEmbedPath() {
                if (this.data.video.indexOf("yout") > -1) {

                    var regExp = /^.*((youtu.be\/)|(v\/)|(\/u\/\w\/)|(embed\/)|(watch\?))\??v?=?([^#&?]*).*/;
                    var match = this.data.video.match(regExp);
                    var videoId = (match && match[7].length === 11) ? match[7] : '';

                    return 'https://www.youtube-nocookie.com/embed/' + videoId;
                } else {
                    return this.data.video;
                }
            }
        }
    }
</script>

<style lang="scss" scoped>
    .text-block {
        background: url("../../assets/img/logo-bg-tile.png") #F0F0F0;
        padding: 50px 0;
        color: #828282;
        font-size: 20px;

        .title {
            font-size: 40px;
            margin-bottom: 20px;
            @media (max-width: 576px) {
                font-size: 24px;
            }
        }

        .media-holder {
            position: relative;

            .video-play-button {
                display: block;
                position: absolute;
                left: 0;
                right: 0;
                bottom: 0;
                top: 0;
                background: url("../../assets/img/video.png") center no-repeat;
            }

            .video-embed {
                width: 100%;
                height: 400px;
                border: 10px solid #ffffff;
                border-radius: 60px;
                overflow: hidden;
            }
        }

        .content ::v-deep {
            h2 {
                display: block;
                text-align: center;
                color: #00A0E9;
                font-weight: bold;
                font-size: 40px;
                margin-bottom: 20px;


                @media (max-width: 576px) {
                    font-size: 24px;
                }
            }
        }

    }
</style>
