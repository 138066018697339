<template>


    <div class="mb-3">
        <label class="form-label" for="inputTitle">Title</label>
        <input type="text" class="form-control" id="inputTitle" :value="inputData.title"
               @input="$emit('dataChange', {key: 'title', val: $event.target.value})">
    </div>

    <div class="mb-3">
        <label class="form-label" for="titleColor">title color</label>
        <input type="color" class="form-control  form-control-color" id="titleColor" :value="inputData.titleColor"
               @input="$emit('dataChange', {key: 'titleColor', val: $event.target.value})">
    </div>

    <div class="mb-3">
        <label class="form-label" >Text</label>
        <QuillEditor theme="snow" toolbar="minimal" :content="inputData.text" contentType="html"
                     @update:content="this.$emit('dataChange', {key: 'text', val: $event})" />
    </div>
    <div class="mb-3">
        <label class="form-label" for="inputBg">Background color</label>
        <input type="color" class="form-control  form-control-color" id="inputBg" :value="inputData.background"
               @input="$emit('dataChange', {key: 'background', val: $event.target.value})">
    </div>


    <div class="mb-3">
        <label class="form-label">Photo</label>
        <file-upload :file-path="inputData.backgroundPhoto"
                     @changed="photoChanged"/>
    </div>


    <div class="mb-3">
        <label class="form-label" for="photoLocation">Photo position</label>
        <select  class="form-control" id="photoLocation" :value="inputData.photoPosition"
               @input="$emit('dataChange', {key: 'photoPosition', val: $event.target.value})">
            <option value="">none</option>
            <option value="background">background</option>
            <option value="left">in left</option>
            <option value="right">in right</option>
        </select>
    </div>


</template>

<script>
    import FileUpload from "../FileUpload";
    import { QuillEditor } from '@vueup/vue-quill'
    import '@vueup/vue-quill/dist/vue-quill.snow.css';


    export default {
        name: "TextBlockBasicAdmin",
        components: {QuillEditor, FileUpload},
        props: ['inputData'],
        emits: ['dataChange'],
        methods: {
            photoChanged(data) {
                this.$emit('dataChange', {key: 'backgroundPhoto', val: data})
            },
        }
    }
</script>

<style lang="scss" scoped>
</style>