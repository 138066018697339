<template>

    <section class="text-block" :style="{
        'background-color': data.background
    , 'background-image': ` ${data.photoPosition ==='background'?`url(${mediaBase}${data.backgroundPhoto})`:`none`}`}">

        <div class="container">

            <div class="title" v-html="data.title" v-if="data.title" :style="{
        'color': data.titleColor}"></div>


            <div class="row">

                <div class="col-md" v-if="data.photoPosition ==='left'">
                    <img :src="`${mediaBase}${data.backgroundPhoto}`" class="img-fluid">
                </div>

                <div class=" col-md text-content" v-html="data.text" v-if="data.text != ''">

                </div>

                <div class="col-md" v-if="data.photoPosition ==='right'">
                    <img :src="`${mediaBase}${data.backgroundPhoto}`" class="img-fluid">
                </div>

            </div>
        </div>


    </section>

</template>

<script>

    import {mediaBase} from "../../repositories/Repository";

    export default {
        name: "TextBlockBasic",
        props: ['data'],
        data() {
            return {
                mediaBase: mediaBase
            }
        }
    }
</script>

<style lang="scss" scoped>
    .text-block {
        padding: 50px 0;
        color: #828282;
        background-size: contain;
        background-position: center;
        background-repeat: no-repeat;

        .title {
            text-align: center;
            font-size: 30px;
            line-height: 40px;
            font-weight: bold;
            margin-bottom: 20px;

            @media (max-width: 576px) {
                font-size: 24px;
            }
        }

        .text-content {
            font-size: 20px;
            line-height: 27px;

            @media (max-width: 576px) {
                font-size: 14px;
                line-height: 18px;
                text-align: justify;
            }

          ::v-deep {
                h2 {
                    display: block;
                    text-align: center;
                    color: #00A0E9;
                    font-weight: bold;
                    font-size: 40px;
                    margin-bottom: 20px;
                }
            }
        }


      ::v-deep .ql-align-center {
            text-align: center;
        }

      ::v-deep ul {
            list-style: none;


            @media (max-width: 576px) {
                padding-left: 0;
            }

            li {
                &:before {
                    content: '';
                    display: inline-block;
                    width: 10px;
                    height: 10px;
                    background-color: #00A0E9;
                    border-radius: 3px;
                    margin-right: 10px;
                }

                &:nth-child(4n+1) {
                    &:before {
                        background: #D55163;
                    }
                }

                &:nth-child(4n+2) {
                    &:before {
                        background: #DE7A2F;
                    }
                }

                &:nth-child(4n+3) {
                    &:before {
                        background: #86B462;
                    }
                }
            }
        }

    }
</style>
