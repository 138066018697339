<template>

    <section class="text-block">

        <div class="container">

            <div class="title" v-html="data.title" v-if="data.title"></div>


            <div class=" text-center  mt-4" v-if="data.photo">
                <img :src="`${mediaBase}${data.photo}`" class="w-100" style="max-width: 300px;" :alt="data.title">
            </div>


            <div v-if="data.file" class="text-center mt-4">
                <a class="button orange small mx-4" :href="`${mediaBase}${data.file}`" target="_blank">
                    {{data.buttonText}}
                </a>
            </div>

            <div v-if="data.link" class="text-center mt-4">
                <a class="button orange small mx-4" :href="`${data.link}`" target="_blank">
                    {{data.buttonText}}
                </a>
            </div>

        </div>


    </section>

</template>

<script>

    import {mediaBase} from "../../repositories/Repository";

    export default {
        name: "LawDownload",
        props: ['data'],
        data() {
            return {
                mediaBase: mediaBase
            }
        }
    }
</script>

<style lang="scss" scoped>
    .text-block {
        padding: 50px 0;
        color: #828282;
        background-size: contain;
        background-position: center;
        background-repeat: no-repeat;

        .title {
            text-align: center;
            font-size: 30px;
            line-height: 40px;
            font-weight: bold;
            margin-bottom: 20px;
            color: #00A0E9;
        }


    }
</style>