<template>


    <div class="mb-3 form-floating">
        <input type="text" class="form-control" id="inputTitle" :value="inputData.title"
               @input="$emit('dataChange', {key: 'title', val: $event.target.value})">
        <label for="inputTitle">Title</label>
    </div>

    <div class="mb-3">
        <label class="form-label">Text</label>
        <QuillEditor theme="snow" toolbar="minimal" :content="inputData.text" contentType="html"
                     @update:content="this.$emit('dataChange', {key: 'text', val: $event})"/>
    </div>

    <div class="row">
        <div class="col-md-6">
            <div class="mb-3">
                <label class="form-label">Photo</label>
                <file-upload :file-path="inputData.photo"
                             @changed="photoChanged"/>
            </div>
        </div>
        <div class="col-md-6">
            <div class="mb-3">
                <label class="form-label">Video</label>
                <file-upload :file-path="inputData.videoFile"
                             @changed="videoChanged"/>
            </div>
        </div>
    </div>




    <div class="mb-3 form-floating">
        <input type="text" class="form-control" id="inputVideo" :value="inputData.video"
               @input="$emit('dataChange', {key: 'video', val: $event.target.value})">
        <label for="inputTitle">Video link</label>
    </div>

</template>

<script>

    import {QuillEditor} from '@vueup/vue-quill'
    import '@vueup/vue-quill/dist/vue-quill.snow.css';

    import FileUpload from "../FileUpload";

    export default {
        name: "TextBlockLeftAdmin",
        components: {FileUpload, QuillEditor},
        props: ['inputData'],
        emits: ['dataChange'],
        methods: {
            photoChanged(data) {
                this.$emit('dataChange', {key: 'photo', val: data})
            },
            videoChanged(data) {
                this.$emit('dataChange', {key: 'videoFile', val: data})
            }
        }
    }
</script>

<style lang="scss" scoped>
</style>