<template>
    <section class="sponsors">

        <div class="container">

            <h2 class="title" v-if="data.title">{{data.title}}</h2>

            <div class="row justify-content-center" v-if="screenSize>768">

                <div class="col-md-2 col-6 mb-4" v-for="(s,i) in data.sponsors" :key="i">
                    <a target="_blank" :href="s.url" class="sponsor-item">
                        <img :src="`${mediaBase}${s.photo}`" alt="sponsor">
                    </a>
                </div>

            </div>

            <div class="position-relative px-5" v-else>

                <div class="swiper-button-prev sponsor-prev"></div>
                <div class="swiper-button-next sponsor-next"></div>

                <swiper :slides-per-view="2" :autoplay="{delay: 5000,}" :space-between="20"
                        :navigation="{ nextEl: '.sponsor-next', prevEl: '.sponsor-prev', }">
                    <swiper-slide v-for="(s,i) in data.sponsors" :key="i">
                        <a target="_blank" :href="s.url" class="sponsor-item">
                            <img :src="`${mediaBase}${s.photo}`" alt="sponsor">
                        </a>
                    </swiper-slide>

                </swiper>
            </div>


        </div>

    </section>
</template>

<script>
    import {mediaBase} from "../../repositories/Repository";

    import SwiperCore, {A11y, Navigation} from 'swiper';
    import {Swiper, SwiperSlide} from 'swiper/vue';

    import 'swiper/swiper.scss';

    SwiperCore.use([A11y, Navigation]);
    // Import Swiper styles
    import "swiper/swiper-bundle.css";

    export default {
        name: "SponsorList",
        components: {
            Swiper,
            SwiperSlide,
        },
        props: ["data"],
        data() {
            return {
                mediaBase: mediaBase,
                screenSize: 0
            }
        },
        mounted() {
            this.screenSize = window.innerWidth
        }
    }
</script>

<style lang="scss" scoped>

    .sponsors {
        padding: 50px 0;

        .title {
            text-align: center;
            font-size: 40px;
            color: #00A0E9;
            margin-bottom: 50px;
            font-weight: bold;
        }

        .sponsor-item {
            display: block;
            background: url("../../assets/img/sponsor-bg.png");
            background-size: 100% 100%;
            position: relative;
            padding-bottom: 60%;

            img {
                position: absolute;
                max-width: 80%;
                max-height: 80%;
                left: 50%;
                top: 50%;
                transform: translateX(-50%) translateY(-50%);
            }
        }
    }


    @media (max-width: 576px) {
        .sponsors {
            .title {
                font-size: 24px;
            }
        }

        .swiper-wrapper {
            width: 80%;
            margin: 0 auto;
        }
    }

    .swiper-button-prev, .swiper-button-next{
        font-size: 25px !important;
    }

</style>