<template>
    <div class="staff">

        <div class="container">

            <div class="text-center">
                <h2 class="title">{{data.title}}</h2>
            </div>

            <div class="row">
                <div class="col-md-6 order-2 order-md-1">
                    <p class="lead">{{data.subtitle}}</p>
                    <div class="person" v-for="(p,i) in data.persons" :key="i">
                        <span class="position">{{p.position}}</span> <br>
                        <span class="name">{{p.name}}</span>
                    </div>
                </div>
                <div class="col-md-6 order-1 order-md-2">
                    <img :src="`${mediaBase}${data.photo}`" class="img-fluid">
                </div>
            </div>

        </div>

    </div>

</template>

<script>
    import {mediaBase} from "../../repositories/Repository";

    export default {
        name: "StaffList",
        props: ['data'],
        data() {
            return {
                mediaBase: mediaBase
            }
        }
    }
</script>

<style lang="scss" scoped>


    .staff {
        padding: 50px 0;
        color: #828282;


        .title {
            font-size: 40px;
            color: #00A0E9;
            font-weight: bold;


            @media (max-width: 576px) {
                font-size: 24px;
            }
        }

        .lead {
            font-size: 24px;
            font-weight: bold;

          ::v-deep span {
                color: #DE7A2F;
            }


            @media (max-width: 576px) {
                font-size: 14px;
                text-align: center;
            }
        }

        p {
            font-size: 18px;
            font-weight: normal;

            @media (max-width: 576px) {
                font-size: 14px;
            }
        }

        .person {
            font-size: 20px;

            @media (max-width: 576px) {
                font-size: 14px;
                text-align: center;
            }

            .position {
                color: #00A0E9;
                font-weight: bold;
                line-height: 1.25px;
            }

        }
    }


</style>
