<template>

    <section class="info-blocks">

        <div class="container">
            <div class="title" v-html="data.title">
            </div>


            <div class="row justify-content-center">

                <div class="col-md-4 mb-5" v-for="(b,i) in data.blocks" :key="i">

                    <div class="row info-block" @click.prevent="blockClicked(b.amount)">
                        <div class="col-5">
                            <div class="image">
                                <img class="icon" :src="`${mediaBase}${b.photo}`" :alt="b.title">
                                <span>{{b.amount}}<sup><img src="../../assets/img/azn-white.svg"></sup> </span>
                            </div>
                        </div>
                        <div class="col-7">
                            <div class="title">{{b.title}}</div>
                            <div class="text">{{b.text}}</div>
                        </div>
                    </div>

                </div>

            </div>

        </div>

    </section>

</template>

<script>
    import {mediaBase} from "../../repositories/Repository";
    import {mapState} from "vuex";


    export default {
        name: "InfoBlocksFirst",
        components: {},
        props: ['data'],
        data() {
            return {
                mediaBase: mediaBase,
                screenSize: 0
            }
        },
        computed: {
            ...mapState({
                settings: state => state.app.settings,
                language: state => state.app.frontLanguage,
            }),
        },
        methods: {
            blockClicked(amount) {
                this.$router.push({
                    path: `/${this.language.code}/${this.settings['donation_page_slug']}`,
                    query: {
                        "amount": amount
                    }
                });
            }
        },
        mounted() {
            this.screenSize = window.innerWidth
        }
    }
</script>

<style lang="scss" scoped>
    .info-blocks {
        padding: 50px 0;

        .title {
            font-size: 40px;
            line-height: 1.25;
            color: #00A0E9;
            text-align: center;
            margin-bottom: 50px;


            @media (max-width: 576px) {
                font-size: 24px;
            }
        }

        .info-block {
            cursor: pointer;


            @media (max-width: 576px) {
                margin: 0 20px;
                border-bottom: 1px solid #828282;
                padding-bottom: 10px;
            }

            .image {

                background: #F2F2F2;
                border-radius: 100%;
                padding: 30px;
                position: relative;

                img.icon {
                    width: 100%;
                }

                span {
                    display: inline-block;
                    min-width: 50px;
                    padding: 0 10px;
                    height: 50px;
                    text-align: center;
                    line-height: 50px;
                    font-size: 25px;
                    position: absolute;
                    top: 0;
                    right: 0;
                    border-radius: 100%;
                    background: #DE7A2F;
                    color: #ffffff;
                    font-weight: bold;


                    @media (max-width: 576px) {
                        font-size: 20px;
                        top: -30px;
                        height: 60px;
                        line-height: 60px;
                    }
                }

            }

            .title {
                font-size: 35px;
                text-align: left;
                margin-bottom: 10px;

                @media (max-width: 576px) {
                    font-size: 24px;
                }
            }

            .text {
                font-size: 20px;
                color: #929292;

                @media (max-width: 576px) {
                    font-size: 14px;
                }
            }

        }


    }
</style>