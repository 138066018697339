<template>
    <router-view/>
</template>

<script>

    import {getSettings, getTranslations} from "./repositories/GeneralDataRepository";
    import {mapState} from "vuex";

    export default {
        data() {
            return {
            }
        },
        computed: {

            ...mapState({
                language: state => state.app.frontLanguage,
                languages: state => state.app.languages
            })

        },

        watch: {
            language() {
                getSettings(this.language.code).then((settings) => {
                    this.$store.dispatch('app/setSettings', settings);
                })

                getTranslations(this.language.code).then((translations) => {
                    this.$store.dispatch('app/setTranslations', translations);
                })
            }
        },
        created() {


        },
    }
</script>


<style lang="scss">

    $primary: #00A0E9;

    @import "assets/fonts/blinker/style.css";
    @import "assets/font-awesome/css/font-awesome.min.css";

    body {
        font-family: 'Blinker Regular', sans-serif !important;
    }


    .button {
        display: inline-block;
        padding: 12px 25px;
        font-size: 25px;
        text-decoration: none;
        font-weight: bold;
        border: none;
        background: none;
        cursor: pointer;

        &.small {
            padding: 7px 15px;
            font-size: 15px;
        }

        &.block {
            width: 100%;
        }

        &.orange {
            background: url("assets/img/button-bg-orange.png");
            background-size: 100% 100%;
            color: #ffffff;
        }

        &.blue {
            background: url("assets/img/button-bg-blue.png");
            background-size: 100% 100%;
            color: #ffffff;
        }

        &.white {
            background: url("assets/img/button-bg-white.png");
            background-size: 100% 100%;
            color: #00A0E9;
        }
    }

    @media (max-width: 576px) {
        .button {
            padding: 10px 25px;
            font-size: 16px;
        }
    }

</style>

