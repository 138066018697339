<template>
    <div>
        <div class="container">

            <div class="row my-5">
                <div class="col-lg-8">
                    <category-cloud :active="activeCategory" showall="1" @categoryClicked="goToCategory($event)"/>
                </div>
                <div class="col-lg-4 d-none d-md-flex">
                    <subscribe-block/>
                </div>
            </div>

            <div class="row mb-5">

                <div class="alert alert-info" v-if="filteredNewsList.length <1">
                    {{translations.news_no_news_matched}}
                </div>

                <div class="col-md-4 col-sm-12 mb-4" v-for="s in filteredNewsList" :key="s.id">

                    <div class="news-item">
                        <router-link :to="`/post/${s.slug}`">
                            <a class="news-image" :style="{'background-image':  `url('${mediaBase}${s.photo}')`}"
                               :alt="s.title"> </a>
                        </router-link>
                        <div class="news-text">
                            <router-link :to="`/post/${s.slug}`" class="news-title">
                                {{s.title}}
                            </router-link>
                            <p v-html="newsText(s.data).substring(0,120)">
                            </p>
                            <div class="text-center">
                                <router-link :to="`/post/${s.slug}`">{{translations.news_read_more}} ></router-link>
                            </div>
                        </div>
                    </div>

                </div>

            </div>

            <div class="text-center mb-5" v-if="isLoading">

                 {{translations.news_loading}}

            </div>
            <div class="text-center mb-5" v-if="page*size <= news.length">

                <a class="button orange" @click="loadMore">
                    {{translations.news_load_more}}
                </a>

            </div>

            <div class="d-block d-md-none mb-5">
                <subscribe-block/>
            </div>


        </div>
    </div>
</template>

<script>
    import {mediaBase} from '../../repositories/Repository';
    import {getNewsList} from "../../repositories/GeneralDataRepository";

    import CategoryCloud from "../CategoryCloud";
    import SubscribeBlock from "../SubscribeBlock";
    import {mapState} from "vuex";

    export default {
        name: "NewsList",
        components: {
            SubscribeBlock,
            CategoryCloud,
        },
        data() {
            return {
                mediaBase: mediaBase,
                news: [],
                page: 1,
                size: 9,
                activeCategory: 0,
                isLoading:false
            }
        },
        computed: {
            ...mapState({
                translations: state => state.app.translations,
                settings: state => state.app.settings
            }),
            header() {
                return {
                    title: this.translations.news_page_title,
                    background: '/img/news.png',
                    type: 'local'
                }
            },
            filteredNewsList() {

                if (this.activeCategory < 1) {
                    return this.news;
                } else {
                    return this.news.filter(n => n.category.id === this.activeCategory)
                }

            }
,
            languageCode() {
                return this.$route.params.language;
            },
        },
        methods: {

            getCurrentPage() {
                this.isLoading = true;
                getNewsList(this.page, this.size).then(({code, news}) => {

                    if (code === 200) {
                        if (this.page === 1) {
                            this.news = news;
                        } else {
                            this.news.push(...(news));
                        }

                        this.isLoading = false;
                    }

                })
            }
            ,
            loadMore() {
                this.page++;
                this.getCurrentPage();
            },
            goToCategory(categoryId) {
                this.$router.push(`/${this.languageCode}/${this.settings.news_list_slug}?cat=${categoryId}`);
            },
            newsText(originalString) {
                return originalString.replace(/(<([^>]+)>)/gi, " ")
            }
        },
        watch: {
            '$route'() {
                this.getCurrentPage();
                this.activeCategory = parseInt(this.$route.query['cat']) || 0;
            }
        },

        created() {
            this.getCurrentPage();

            this.activeCategory = parseInt(this.$route.query['cat']) || 0;

            getNewsList(1, 3).then(d => {

                if (d.code === 200) {
                    this.latest = d.news;
                }

            })
        }
    }
</script>

<style lang="scss" scoped>


    .news-item {
        padding: 10px;
        font-size: 23px;

        @media (max-width: 576px) {
            font-size: 18px;
        }

        .news-image {
            display: block;
            width: 100%;
            border-radius: 40px;
            padding-bottom: 75%;
            overflow: hidden;

            background-size: cover;
            background-repeat: no-repeat;
            background-position: center;
            border: 5px solid #ffffff;
            box-shadow: 0 0 6px #828282;
        }

        .news-text {

            padding: 20px 30px;
            text-align: justify;

            @media (max-width: 576px) {
                padding: 15px;
            }

            .news-title {
                margin-bottom: 20px;
                color: #4F4F4F;
                font-weight: bold;
                text-decoration: none;
                display: block;
                height: 70px;
                overflow: hidden;

                @media (max-width: 576px) {
                    height: 60px;
                    margin-bottom: 10px;
                }

            }

            p {
                color: #929292;
                text-decoration: none;
                display: block;
                font-size: 20px;
                margin-bottom: 20px;
                height: 90px;
                overflow: hidden;
                line-height: 1.5;
                @media (max-width: 576px) {
                    font-size: 18px;
                    line-height: 1.25;
                    height: 68px;
                }

            }

            a {
                color: #00A0E9;
                text-decoration: none;
            }
        }


    }


</style>